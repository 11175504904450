import { Capacitor } from "@capacitor/core";
import packageInfo from "../../package.json";
import type { Configuration } from "../model/Configuration";

const Config:{
  TEST: boolean,
  DEBUG: boolean,
  imageUrlBase: string,
  fbAppID: string,
  cartVersionNumber: number,
  googleApiKey: string,
  mobileMaxWidth: number,
  email_address_customer_service: string,
  version: string,
  APIBaseURL: string,
  tagManagerID: string,
  ios: boolean,
  android: boolean,
  mobile: boolean,
  mobileNavbarHeightDefault: number,
  wsURL: string,
  wsURLUnregistered: string,
  mapTilerAPIKey: string,
  configurations: Configuration[],
} = {
  TEST: true,
  DEBUG: false,
  imageUrlBase: "https://www.blundee.com/data/img/",
  fbAppID: "2954730497943102",
  cartVersionNumber: 5,
  googleApiKey: "AIzaSyBO6kU3ezNvQHDwIjpUN_Li3EMLUTq9HGk",
  mobileMaxWidth: 800,
  email_address_customer_service: "info@blundee.com",
  version: packageInfo.version,
  APIBaseURL: "https://backend.enniakarok.hu",
  tagManagerID: "GTM-K96FMWR",
  ios: Capacitor.getPlatform() === "ios",
  android: Capacitor.getPlatform() === "android",
  mobile: false,
  mobileNavbarHeightDefault: 50,
  wsURL: "wss://backend.enniakarok.hu/ws/client/registered/",
  wsURLUnregistered: "wss://backend.enniakarok.hu/ws/client/unregistered",
  mapTilerAPIKey: "GJBnQuWYGepI3CB0RtJK",
  configurations: [],
};
Config.mobile = Config.ios || Config.android;

if (Config.TEST) {
  Config.tagManagerID = "GTM-TEST";
  Config.fbAppID = "265493057815799";
  Config.APIBaseURL = "https://backend.test.enniakarok.hu";
  Config.imageUrlBase = "https://test.enniakarok.hu/data/img/";
  Config.wsURL = "wss://backend.test.enniakarok.hu/ws/client/registered/";
  Config.wsURLUnregistered = "wss://backend.test.enniakarok.hu/ws/client/unregistered";
  Config.mapTilerAPIKey = "u48N9dj8Ez0RskcDKa4U";

  if (Config.DEBUG) {
    Config.wsURL = "wss://localhost:8000/client/registered/";
    Config.wsURLUnregistered = "wss://localhost:8000/client/unregistered";
    Config.APIBaseURL = "http://localhost:3332";
  }
} else if (Config.DEBUG) {
  Config.APIBaseURL = "http://localhost:3333";
}

Config.categoryImageUrlBase = Config.imageUrlBase + "category/";
Config.partnerImageUrlBase = Config.imageUrlBase + "partner/";
Config.productImageUrlBase = Config.imageUrlBase + "product/";

Config.getBoolean = function (name: string): boolean {
  let v: any = Config.getValue(name);
  if (!v)
    return undefined;
  return v === true || v === 1 || v === "1" || v === "y" || v === "Y"
}

Config.getNumber = function (name: string): number {
  let v: any = Config.getValue(name);
  if (!v)
    return undefined;
  return parseFloat(v);
}

Config.getString = function (name: string): string {
  let v: any = Config.getValue(name);
  if (!v)
    return undefined;
  return "" + v;
}

Config.getValue = function (name: string): any {
  for (let configuration of Config.configurations) {
    if (configuration.key === name)
      return configuration.value;
  }
  return undefined;
}

export default Config;

export type ShopLabel = {id: number, label: string[], bg: string};

//Possible configuration keys which are coming from backend dynamically
export const ConfigKeys = {
  enable_table_reservation: "enable_table_reservation",
  google_forms_url: "google_forms_url",
  enable_homepage_google_forms: "enable_homepage_google_forms",
  homepage_google_forms_details: "homepage_google_forms_details",
  homepage_google_forms_title: "homepage_google_forms_title",
  sales_offers: "sales_offers",
  sales_phone: "sales_phone",
  sales_mail: "sales_mail",
  sales_video: "sales_video",
  sales_video_title: "sales_video_title",
  sales_video_yt_id: "sales_video_yt_id",
  sales_pos: "sales_pos",
  sales_structure: "sales_structure",
  sales_main_title: "sales_main_title",
  what_is_blundee_page_title: "what_is_blundee_page_title",
  what_is_blundee_page_structure: "what_is_blundee_page_structure",
  order_not_available_text: "order_not_available_text",
  enable_rizi_kupon: "enable_rizi_kupon",
  rizi_coupon_title: "rizi_coupon_title",
  rizi_coupon_description: "rizi_coupon_description",
  rizi_coupon_validity: "rizi_coupon_validity",
  rizi_coupon_img: "rizi_coupon_img",
  rizi_coupon_handles: "rizi_coupon_handles",
  start_with_login: "start_with_login",
  show_address_menuitem: "show_address_menuitem",
  enable_nonprofit_donation_on_order: "enable_nonprofit_donation_on_order",
  customer_service_phone: "customer_service_phone",
  shop_labels: "shop_labels",
}
